'use client'

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { View as ReactNativeView } from 'react-native';
import { Text as ReactNativeText } from 'react-native';
import { TextInput as ReactNativeTextInput } from 'react-native';
import { Pressable as ReactNativePressable } from 'react-native';
import { ScrollView as ReactNativeScrollView } from 'react-native';

export default function __App() {
  var [renderChildren, setRenderChildren] = useState(<App />);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      window.require = (_from) => {
        if (_from === 'react') return require('react');
        if (_from === 'react') return require('react');
        if (_from === 'react-native') return require('react-native');
        if (_from === 'react-native') return require('react-native');
        if (_from === 'react-native') return require('react-native');
        if (_from === 'react-native') return require('react-native');
        if (_from === 'react-native') return require('react-native');
      }

      if (event.data.payload) setRenderChildren(eval(event.data.payload));
    });
  }, []);

  return renderChildren;
}

var useStylesheet = (stylesheet) => {
    var styleElement = document.createElement('style');

    styleElement.innerHTML = stylesheet;

    document.head.appendChild(styleElement);
  };

function App(props) {
  var [counter, setCounter] = useState(1);

  return (
    <View style={{height: "100%", justifyContent: "center", alignItems: "center"}}>
      <Button onPress={() => setCounter(counter + 1)}>
        <Text
          text={'Click me asasdf: ' + counter}
          style={{color: "red"}}
        />
      </Button>
    </View>
  );
}

useStylesheet(`body {
    margin: 0;
    padding: 0;
  }`);

useStylesheet(`.View {
  display: flex;
  flex-direction: column;
}`);

function View(props) {
  return (
    <ReactNativeView {...props}>
      {props.children}
    </ReactNativeView>
  );
}

function Text(props) {
  var {text, ...restProps} = props;

  return (
    <ReactNativeText
      {...restProps}
       style={{...props.style}}
    >
      {text}
    </ReactNativeText>
  );
}

function TextInput(props) {
  var handleChange = event => {
  
  };

  return (
    <ReactNativeTextInput {...props} onChange={handleChange} onBlur={handleBlur}/>
  );
}

function Link(props) {
  return (
    <ReactNativeLink {...props} href={url}/>
  );
}

function Video(props) {
}

function Image(props) {
}

useStylesheet(`.Button {
  display: flex;
  flexDirection: column;
  appearance: none;
  -webskit-appearance: none;
  background-color: transparent;
  border: none;
}`);

function Button(props) {
  var {className, onPress, text, ...restProps} = props;

  return (
    <ReactNativePressable {...props} onPress={onPress}>
      {text || props.children}
    </ReactNativePressable>
  );
}

function ScrollView(props) {
  return (
    <ReactNativeScrollView
      {...props}
      horizontal={horizontal}
      scrollEnabled={scrollEnabled}
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
    />
  );
}

